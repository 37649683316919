import React from 'react'

import { makeStyles, Grid } from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

function CalendarPage() {
  const classes = useStyles()

  return (
    <div style={{ height: '100%' }}>
      <Grid container justifyContent="center" className={classes.root}>
        <Grid item xs={12}>
          <iframe
            src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23F6F6F6&ctz=America%2FSao_Paulo&showPrint=0&showTitle=0&showTz=0&src=Y18yOWZjYmYyMzY5MDE1YjdlZWZkNGZjMmE0MTg3ZmY0ZjEyZDcxZGI2YjM4NzM5Mzc5YmI5YWFmNzY1OWFhYzk2QGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&src=Y18yNjM4NDdhNjQ3YjcyM2Y4OTA0MTAyZDVmZTQxYTdjZDMyNDMzMWQ5N2Y1YzYxYmE0NjI3NTc2N2VmODU3ZWI4QGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&src=Y29udGF0b0BuaXNzdWxnYWxhLmNvbQ&color=%234285F4&color=%23795548&color=%23009688"
            style={{ border: 'none' }}
            title="calendar"
            width="100%"
            height="100%"
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default CalendarPage
