import { call, takeLatest, put, select, debounce } from 'redux-saga/effects'
import { find } from 'lodash'
import configureApi from '~/helpers/api'

import {
  Types as EquipmentsTypes,
  Creators as EquipmentsActions,
} from '../ducks/equipments'

const optionsSelector = ({ equipments }) => equipments.options

function* getEquipments({ responsibleId }) {
  const fromUser = !!responsibleId

  const options = yield select(optionsSelector)
  const getFilter = (property) => {
    const filterValue = find(options.filters, { name: property })
    return filterValue.value
  }

  const search = []
  if (options.search) {
    search.push(`cod_equipment,${options.search}`)
    search.push(`equipment_type_name,${options.search}`)
    search.push(`dealership_name,${options.search}`)
    search.push(`department_name,${options.search}`)
    search.push(`user_name,${options.search}`)
    search.push(`brand,${options.search}`)
    search.push(`model,${options.search}`)
  }

  try {
    const api = configureApi()
    const formattedParams = {
      pagination: 1,
      page: options.page + 1,
      limit: options.limit,
      order: `${options.sort},${options.direction}`,
      search,
      'where[equipment_type_id]': getFilter('equipment_type_id'),
      'where[department_id]': getFilter('department_id'),
      'where[dealership_id]': getFilter('dealership_id'),
      'where[responsible_id]': getFilter('responsible_id'),
    }
    if (fromUser) {
      const { data } = yield call(api.get, 'equipments', {
        params: {
          pagination: 1,
          page: options.page + 1,
          limit: options.limit,
          order: `${options.sort},${options.direction}`,
          'where[responsible_id]': responsibleId,
        },
      })
      yield put(EquipmentsActions.getEquipmentsSuccess(data.data, data.total))
    } else {
      const { data } = yield call(api.get, 'equipments', {
        params: formattedParams,
      })
      yield put(EquipmentsActions.getEquipmentsSuccess(data.data, data.total))
    }
  } catch (e) {
    yield put(EquipmentsActions.getEquipmentsFailure())
  }
}

function* reloadEquipments() {
  yield put(EquipmentsActions.getEquipmentsRequest())
}

function* getEquipmentsFilterEquipmentsTypes() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'equipment_types', {
      params: {
        pagination: 0,
        order: 'name,asc',
      },
    })
    yield put(EquipmentsActions.getEquipmentsFilterEquipmentsTypeSuccess(data))
  } catch (e) {
    yield put(EquipmentsActions.getEquipmentsFilterEquipmentsTypeFailure())
  }
}

function* getEquipmentsFilterDealerships() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'dealerships', {
      params: {
        pagination: 0,
        order: 'sort,asc',
      },
    })
    yield put(EquipmentsActions.getEquipmentsFilterDealershipsSuccess(data))
  } catch (e) {
    yield put(EquipmentsActions.getEquipmentsFilterDealershipsFailure())
  }
}

function* getEquipmentsFilterDepartments() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'departments', {
      params: {
        pagination: 0,
        order: 'name,asc',
      },
    })
    yield put(EquipmentsActions.getEquipmentsFilterDepartmentsSuccess(data))
  } catch (e) {
    yield put(EquipmentsActions.getEquipmentsFilterDepartmentsFailure())
  }
}

function* getEquipmentsFilterResponsibles() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'users', {
      params: {
        pagination: 0,
        order: 'name,asc',
      },
    })
    yield put(EquipmentsActions.getEquipmentsFilterResponsiblesSuccess(data))
  } catch (e) {
    yield put(EquipmentsActions.getEquipmentsFilterResponsiblesFailure())
  }
}

function* getEquipmentsListQrCode() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'equipments', {
      params: {
        pagination: 0,
      },
    })
    yield put(EquipmentsActions.getEquipmentsListQrCodeSuccess(data))
  } catch (e) {
    yield put(EquipmentsActions.getEquipmentsListQrCodeFailure())
  }
}

export default function* () {
  yield takeLatest(EquipmentsTypes.GET_EQUIPMENTS_REQUEST, getEquipments)
  yield takeLatest(EquipmentsTypes.SET_EQUIPMENTS_SORT, reloadEquipments)
  yield takeLatest(EquipmentsTypes.SET_EQUIPMENTS_PAGE, reloadEquipments)
  yield debounce(1000, EquipmentsTypes.SET_EQUIPMENTS_SEARCH, reloadEquipments)
  yield takeLatest(
    EquipmentsTypes.GET_EQUIPMENTS_FILTER_EQUIPMENTS_TYPE_REQUEST,
    getEquipmentsFilterEquipmentsTypes,
  )
  yield takeLatest(
    EquipmentsTypes.SET_EQUIPMENTS_PROPERTY_FILTER,
    reloadEquipments,
  )

  yield takeLatest(
    EquipmentsTypes.GET_EQUIPMENTS_FILTER_DEALERSHIPS_REQUEST,
    getEquipmentsFilterDealerships,
  )
  yield takeLatest(
    EquipmentsTypes.GET_EQUIPMENTS_FILTER_DEPARTMENTS_REQUEST,
    getEquipmentsFilterDepartments,
  )
  yield takeLatest(
    EquipmentsTypes.GET_EQUIPMENTS_FILTER_RESPONSIBLES_REQUEST,
    getEquipmentsFilterResponsibles,
  )
  yield takeLatest(
    EquipmentsTypes.GET_EQUIPMENTS_LIST_QR_CODE_REQUEST,
    getEquipmentsListQrCode,
  )
}
